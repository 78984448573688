/**
 * Used to push modules into the plugins.js up-front dependency list
 * Consider lazy-loading optional modules (e.g. analytics)
 */
define('componentHelper', ['require', 'siteObj'], function(require, siteObj) {

  let ComponentHelper = function() {
    const DATA_PRIORITY = 'data-component-priority';
    const LOW_PRIORITY = 'low';

    let config = window.THEHUT.config;
    let self = this;

    /**
     * Promote module (adds to dependencies)
     */
    function promote(modules) {
      self.eachItem(modules, 'promote');
    }

    /**
     * Demote module (removes from dependencies)
     */
    function demote(modules) {
      self.eachItem(modules, 'demote');
    }

    function moduleHandler(name, type) {
      let check = window.THEHUT.config.dependencies[name];
      let jsPriorityEnabled = siteObj.config.jsPriorityEnabled;

      if (!type) {
        type = 'promote';
      }

      let moduleAction = {
        source: window.THEHUT.config.paths,
        destination: window.THEHUT.config.dependencies
      };

      if (jsPriorityEnabled) {
        moduleAction.destination = window.THEHUT.config.nonBlockingDependencies;
      }

      if (type === 'demote') {
        check = !check;
        if (jsPriorityEnabled) {
          moduleAction.source = window.THEHUT.config.nonBlockingDependencies;
        } else {
          moduleAction.source = window.THEHUT.config.dependencies;
        }

        moduleAction.destination = window.THEHUT.config.paths;
      }

      // Check the module's path is declared
      if (self.isModule(name)) {

        // Provide info if already promoted/demoted
        if (check) {
          self.log('Module "' + name + '" is already ' + type + 'd');
        }

        // Proceed with promotion/demotion
        else {
          self.swap(name, moduleAction);
        }
      }
    }

    /**
     * Update shim (e.g. local overrides)
     */
    function updateShim(shim) {
      self.eachProperty(shim, function(name) {
        config.shim[name] = shim[name];
      });

      // Tell RequireJS about new shim
      window.requirejs.config({
        shim: shim
      });
    }

    /**
     * Update config (e.g. local overrides)
     */
    function updateConfig(config) {
      window.requirejs.config({
        config: config
      });
    }

    /**
     * Wrap non-array values into array
     */
    function wrap(value) {
      return typeof value === 'object' ? value : [value];
    }

    /**
     * Swap module between objects by name
     */
    function swap(name, params) {
      let source = params && params.source;
      let destination = params && params.destination;

      // Exists at source so add to destination
      if (source[name] && destination) {
        destination[name] = source[name];

        // Only delete when demoting
        if (destination === config.paths) {
          delete source[name];
        }
      }

      // Tell RequireJS about new paths
      window.requirejs.config({
        paths: config.paths
      });
    }

    /**
     * Run callback on each array item
     */
    function eachItem(modules, type) {
      modules = self.wrap(modules);

      for (let i = 0, modulesLength = modules.length; i < modulesLength; i++) {
        self.moduleHandler.call(self, modules[i], type);
      }
    }

    /**
     * Run callback on each object property
     */
    function eachProperty(properties, callback) {
      for (let module in properties) {
        if (properties.hasOwnProperty(module)) {
          callback.call(self, module);
        }
      }
    }

    /**
     * Check modules exists
     */
    function isModule(name) {
      var isModule = !!config.paths[name];

      if (!isModule) {
        self.log('Module "' + name + '" doesn’t exist', 'warn');
      }

      return isModule;
    }

    /**
     * Log info for developers
     */
    function log(message, type) {
      type = type || 'info';

      // Don't log when live
      if (siteObj && siteObj.debugFlag && window.console && window.console[type]) {
        window.console[type](message);
      }
    }

    function requireDependenciesAndComponents() {
      let jsPriorityEnabled = siteObj.config.jsPriorityEnabled;

      if (jsPriorityEnabled) {
        self.requirePrimaryDependencies();
      }

      self.reloadAllComponents();
      self.requireDependencies(jsPriorityEnabled);
    }

    /**
     * Require all dependencies
     */
    function requireDependencies(jsPriorityEnabled) {
      require(Object.keys(jsPriorityEnabled ?
        window.THEHUT.config.nonBlockingDependencies : window.THEHUT.config.dependencies));
    }

    function requirePrimaryDependencies() {
      require(Object.keys(window.THEHUT.config.primaryDependencies));
    }

    function newComponentHelper() {
      return new ComponentHelper();
    }

    function createComponentInstance(componentElement) {
      var componentName = componentElement.getAttribute('data-component');
      // only the else branch should remain after all components have been refactored.
      if (componentElement.hasAttribute('data-componentLoad')
          && componentElement.getAttribute('data-componentLoad') === 'helper') {
        self.promote([componentName]);
      } else {
        if (self.isModule(componentName)) {
          require([componentName], function(componentScript) {
            componentElement.componentObject = new componentScript().init(componentElement);
          });
        }
      }
    }

    function reloadAllComponents(element) {
      element = element || document;

      let componentElements = Array.from(element.querySelectorAll('[data-component]'));
      if (componentElements.length) {
        if (siteObj.config.jsPriorityEnabled) {
          componentElements = self.sortComponentsOnPriority(componentElements);
        }

        for (let i = 0; i < componentElements.length; i++) {
          self.createComponentInstance(componentElements[i]);
        }
      }
    }

    function sortComponentsOnPriority (components) {
      return components.sort(function (a,b) {
        let attr1 = a.hasAttribute(DATA_PRIORITY) ?
          a.getAttribute(DATA_PRIORITY) : LOW_PRIORITY;

        let attr2 = b.hasAttribute(DATA_PRIORITY) ?
          b.getAttribute(DATA_PRIORITY) : LOW_PRIORITY;

        if (attr1 < attr2)
          return -1;
        if ( attr1 > attr2)
          return 1;
        return 0;
      });
    }

    // Expose methods
    self.promote = promote;
    self.demote = demote;
    self.updateShim = updateShim;
    self.updateConfig = updateConfig;
    self.requireDependencies = requireDependencies;
    self.requirePrimaryDependencies = requirePrimaryDependencies;
    self.createComponentInstance = createComponentInstance;
    self.reloadAllComponents = reloadAllComponents;
    self.sortComponentsOnPriority = sortComponentsOnPriority;
    self.requireDependenciesAndComponents = requireDependenciesAndComponents;

    // Expose methods for unit testing only
    self.eachItem = eachItem;
    self.eachProperty = eachProperty;
    self.isModule = isModule;
    self.log = log;
    self.wrap = wrap;
    self.swap = swap;
    self.moduleHandler = moduleHandler;
    self.newComponentHelper = newComponentHelper;

    config.dependencies = config.dependencies || {};
    config.dependencies2 = config.dependencies2 || {};
    config.paths = config.paths || {};
    config.shim = config.shim || {};
    config.modulesLoaded = config.modulesLoaded || [];
  };

  // Start new loader instance
  return new ComponentHelper();
});
